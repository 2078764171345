/*!
 * Agate by Taufik Nurrohman <https://github.com/taufik-nurrohman>
 * ---------------------------------------------------------------
 *
 * #ade5fc
 * #a2fca2
 * #c6b4f0
 * #d36363
 * #fcc28c
 * #fc9b9b
 * #ffa
 * #fff
 * #333
 * #62c8f3
 * #888
 *
 */

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #333;
  color: white;
}

.hljs-name,
.hljs-strong {
  font-weight: bold;
}

.hljs-code,
.hljs-emphasis {
  font-style: italic;
}

.hljs-tag {
  color: #62c8f3;
}

.hljs-variable,
.hljs-template-variable,
.hljs-selector-id,
.hljs-selector-class {
  color: #ade5fc;
}

.hljs-string,
.hljs-bullet {
  color: #a2fca2;
}

.hljs-type,
.hljs-title,
.hljs-section,
.hljs-attribute,
.hljs-quote,
.hljs-built_in,
.hljs-builtin-name {
  color: #ffa;
}

.hljs-number,
.hljs-symbol,
.hljs-bullet {
  color: #d36363;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal {
  color: #fcc28c;
}

.hljs-comment,
.hljs-deletion,
.hljs-code {
  color: #888;
}

.hljs-regexp,
.hljs-link {
  color: #c6b4f0;
}

.hljs-meta {
  color: #fc9b9b;
}

.hljs-deletion {
  background-color: #fc9b9b;
  color: #333;
}

.hljs-addition {
  background-color: #a2fca2;
  color: #333;
}

.hljs a {
  color: inherit;
}

.hljs a:focus,
.hljs a:hover {
  color: inherit;
  text-decoration: underline;
}
